<template>
  <div id="films">
    <div class="page-title-area bg-11 films-bg" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Nobel Legacy Film Series</h2>
          <ul>
            <li class="active">Award-winning documentary films on the inspiring life and work of our Nobel Peace Laureates</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="loading" v-if="films_data === undefined && !films_error" style="height: 700px">
      <Loading />
    </div>

    <FilmsReview v-if="films_data" :films="films_data" />
  </div>
</template>

<script>
  import useSWRV from "swrv";
  import FilmsReview from "../components/films/FilmsReview";
  import api from "../services/api";
  import Loading from "../components/Loading";

  export default {
    name: "Films",
    setup() {
      const {
        data: films_data,
        error: films_error,
        isValidating: films_isValidating
      } = useSWRV(() => `items/films?status=published&sort=-published&fields=id,image,title,subtitle,published,slug,video_image,video_url`, api.fetcher);

      return {
        films_data,
        films_error,
        films_isValidating
      };
    },
    components: {
      Loading,
      FilmsReview
    },
    created() {
      if (this.$route.query.success) {
        setTimeout(() => {
          this.$swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Thank you. We will be in touch soon.',
          });
          this.$router.push('/films')
        },1500)
      }
    }
  }
</script>

<style scoped lang="scss">
  .films-bg {
    background-image: url("../assets/img/dawn,_Ivan,_Dalai_Lama_and_Zabe_.jpg");
    background-size: cover;
    background-position: 0 30%;

    >div {
      position: relative;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000057;
    }
  }
</style>
