<template>
  <div>
    <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <section id="campaign" class="events-section pb-5 pt-100">
        <div class="container">

          <div class="not-found" v-if="!films_data.length">
            No Films
          </div>

          <div class="events-slider row" v-else>
            <div class="col-md-12" v-for="film in films_data" :key="film.id">
              <div class="films-wrapper">
                <div class="row">
                  <div class="col-md-5 pr-md-0">
                    <div class="events-item">
                      <div class="image">
                        <DefaultImage :id="film.image" :width="'100%'" :height="'100%'"/>
                      </div>

                      <div class="content-wrapper">
                        <div class="text" v-if="film.published">
                          <span>{{momentYear(film.published)}}</span>
                        </div>
                        <h4>{{film.title}}</h4>
                        <p>{{film.subtitle}}</p>
                        <div class="join-campaign">
                          <router-link :to="{ name: 'film', params: { url: film.slug } }">
                            <i class="flaticon-curve-arrow"></i>
                            More info
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7 pl-md-0">
                    <div class="d-flex align-items-center h-100">
                      <section id="video" class="video-section">
                        <iframe v-if="film.iframe"
                                :src="getVimeoVideoUrl(film.iframe)" width="100%" height="100%"
                                frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                        <iframe v-else :src="getVideoThumbnail(film.video_url)" frameborder="0"></iframe>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

<!--      <section id="film-slider" class="events-section ptb-100">-->
<!--        <div class="container">-->
<!--          <div class="section-title">-->
<!--            <h2>PeaceJam Special Jury Award</h2>-->
<!--          </div>-->

<!--          <div class="row special-jury-images">-->
<!--            <div class="col-lg-6" ref="mainImg">-->
<!--              <carousel-->
<!--                class="events-image-slider owl-theme"-->
<!--                :dots="false"-->
<!--                :items="1"-->
<!--                :autoplay="true"-->
<!--              >-->
<!--                <div class="item" v-for="(img, i) in images" :key="i" @click="index = i">-->
<!--                  <img :src="require('../../assets/img/'+ img.url)" alt="">-->
<!--                </div>-->
<!--              </carousel>-->
<!--            </div>-->

<!--            <div class="col-lg-6">-->
<!--              <div class="events-item">-->
<!--                <div class="events-content mt-0">-->
<!--                  <p>We partner with the Monte Carlo TV Festival in June to award "The PeaceJam Special Jury"-->
<!--                    Award.</p>-->
<!--                </div>-->
<!--                <div class="events-content">-->
<!--                  <p>This competition rewards the best TV series or documentary that embodies the Billion Acts Campaign-->
<!--                    and/or the spirit of the Nobel Peace Prize. The competition is free and open to the public.</p>-->
<!--                </div>-->
<!--                <div class="events-content">-->
<!--                  <p>Submissions are currently closed for 2020 due to the Covid-19 cancellation of the festival. Stay-->
<!--                    tuned for more information.</p>-->
<!--                </div>-->

<!--                <div class="events-btn">-->
<!--                  <router-link to="/official-rules" class="events-btn-one">-->
<!--                    <i class="flaticon-right-arrow"></i>-->
<!--                    Official Rules-->
<!--                  </router-link>-->

<!--                  <a href="https://goo.gl/forms/L9lXYsFF4vrFtpqa2" target="_blank" rel="noreferrer" class="events-btn-one">-->
<!--                    <i class="flaticon-right-arrow"></i>-->
<!--                    Entry Form-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="events-shape">-->
<!--          <div class="shape-one">-->
<!--            <img src="../../assets/img/events/shape.png" alt="image">-->
<!--          </div>-->

<!--          <div class="shape-two">-->
<!--            <img src="../../assets/img/events/shape2.png" alt="image">-->
<!--          </div>-->
<!--        </div>-->
<!--      </section>-->
    </div>

    <vue-gallery-slideshow
      :images="popupImg"
      :index="index"
      @close="index = null"
    ></vue-gallery-slideshow>
  </div>
</template>

<script>
  import DefaultImage from "../../components/DefaultImage";
  import api from "../../services/api";
  import moment from "moment";
  import carousel from 'vue-owl-carousel';
  import VueGallerySlideshow from "vue-gallery-slideshow";

  export default {
    name: "FilmsReview",
    components: {
      DefaultImage,
      carousel,
      VueGallerySlideshow
    },
    props: ['films'],
    data() {
      return {
        modalVideoUrl: '',
        showModal: false,
        films_data: [],
        loading: true,
        index: null,
        popupImg: [],
        images: [
          {url: 'specialjury/maker-8.jpg'},
          {url: 'specialjury/maker-1.jpg'},
          {url: 'specialjury/maker-2.jpg'},
          {url: 'specialjury/maker-5.jpg'},
          {url: 'specialjury/maker-7.jpg'},
          {url: 'specialjury/maker-3.jpg'},
          {url: 'specialjury/maker-0.jpg'},
          {url: 'specialjury/maker-6.jpg'},
          {url: 'specialjury/maker-4.jpg'},
        ]
      }
    },
    async mounted() {
      window.scrollTo(0, 0);

      setTimeout(() => {
        var mainDiv = this.$refs.mainImg;
        var img = mainDiv.getElementsByTagName('img');
        img.forEach((value) => {
          this.popupImg.push(value.src);
        });
      }, 2000);

      // let prev       = document.querySelectorAll('#film-slider .owl-prev')[0];
      // let next       = document.querySelectorAll('#film-slider .owl-next')[0];
      // prev.innerHTML = '<i class="flaticon-left-arrow"></i>';
      // next.innerHTML = '<i class="flaticon-right-arrow"></i>';

      this.films_data = this.films.data;

      for (let item of this.films_data) {
        if (this.parseVideo(item.video_url).type === 'vimeo') {
          let a = await api.getVimeoVideoId(item.video_url);
          item.iframe = a.data.html;
        }
      }

    },
    methods: {
      getVimeoVideoUrl(iframe) {
        var tmp = document.createElement('div');
        tmp.innerHTML = iframe;
        return  tmp.querySelector('iframe').getAttribute('src');
      },
      getVideoThumbnail (url) {
        var videoObj = this.parseVideo(url);
        if (videoObj.type === 'youtube') {
          return 'https://www.youtube.com/embed/' + videoObj.id;
        }
      },
      parseVideo (url) {
        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
          var type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
          var type = 'vimeo';
        }

        return {
          type: type,
          id: RegExp.$6
        };
      },
      moment(date) {
        return moment(date).format('MMM DD, YYYY');
      },
      momentYear(date) {
        return moment(date).format('YYYY');
      },
    }
  }
</script>

<style scoped lang="scss">
  #video {
    width: 100%;
  }

  .video-section {
    height: 100%;
    position: relative;
    background: #000;

    iframe {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .div {
      iframe {
        position: absolute;
      }
    }

    .video-frame {
      position: relative;
      width: 100%;
      cursor: pointer;
      height: 375px;

      &:hover .play-btn {
        transform: translate(-50%, -50%) scale(1.1);
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, #000000bd, transparent);
      }

      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 20px;
        left: 20px;
        border-radius: 5px;
        bottom: 20px;
        border: 2px solid #ffffff1c;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .play-btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.5s;

        img {
          width: 50px;
        }
      }
    }
  }

  .video-url-modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.71);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .close-modal {
      position: fixed;
      z-index: 99999;
      top: 10px;
      right: 10px;
      color: #000;
      font-size: 25px;
      background: #ffffffde;
      width: 40px;
      border-radius: 50%;
      height: 40px;
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .loading {
    height: 800px;
  }

  .events-slider .events-item .content {
    padding: 30px 30px 30px 112px;
    min-height: 124px;

    .text {
      width: 70px;
      padding: 6px;
    }
  }

  .events-item .events-content p {
    font-weight: 500;
    line-height: 1.5;
    color: #505050;
    font-size: 18px;
  }

  .events-slider .events-item {
    margin-bottom: 0;
    padding: 0;
    position: relative;

    .content-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #fff;
      padding: 20px;
      background: linear-gradient(0deg, black, transparent);

      span {
        padding: 2px 9px;
        background: #FC4040;
        font-size: 14px;
        border-radius: 5px;
        margin-bottom: 5px;
        display: block;
        width: max-content;
      }

      h4, p {
        color: #fff;
      }

      .join-campaign {
        a {
          color: #fff;
          font-size: 16px;

          &:hover {
            color: #31bfd4;
          }
        }
      }
    }
  }

  .events-slider .events-item .image {
    height: 375px;
  }

  .events-slider .events-item .content {
    background-color: transparent;
    box-shadow: none;
  }

  .films-wrapper {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
    margin-bottom: 30px;
  }

  .events-item .events-content {
    padding-left: 0;
  }

  .special-jury-images {
    .item {
      height: 540px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        background: black;
      }
    }
  }

  .rules-modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
    transition: .5s;
    opacity: 0;

    .close {
      position: fixed;
      color: #000;
      top: 10px;
      right: 25px;
      z-index: 1;
      cursor: pointer;
    }

    &.open {
      visibility: visible;
      opacity: 1;
    }

    .close-area {
      width: 60%;
      height: 100%;
    }

    .rules-modal-content {
      width: 40%;
      padding: 40px;
      background-color: #fff;
      visibility: hidden;
      transform: translateX(100%);
      transition: .5s;
      overflow-y: auto;
      height: 100vh;
      overflow-y: auto;

      &.open {
        visibility: visible;
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 1199px) {
    .events-slider .events-item .image {
      height: 300px;
    }
    .video-section {
      .video-frame {
        height: 300px;
      }
    }
  }

  @media (max-width: 991px) {
    .films-wrapper {
      margin-bottom: 50px;
    }
    .events-slider .events-item .image {
      height: 300px;
    }
    .video-section {
      .video-frame {
        height: 300px;
      }
    }
  }

  @media (max-width: 1024px) {
    .rules-modal .close-area {
      width: 50%;
    }
    .rules-modal .rules-modal-content {
      width: 50%;
    }
  }

  @media (max-width: 991px) {
    .rules-modal .close-area {
      width: 30%;
    }
    .rules-modal .rules-modal-content {
      width: 70%;
    }
  }

  @media (max-width: 767px) {
    .video-section {
      height: 290px;
    }
  }

  @media (max-width: 600px) {
    .rules-modal .close-area {
      width: 0;
    }
    .rules-modal .rules-modal-content {
      width: 100%;
    }
  }

</style>
